var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-link',{staticClass:"d-flex deal-card-widget flex-column\n      position-relative no-gutters bg-white",class:[{'blue-border': _vm.featured && _vm.displayFeaturedBorder}],attrs:{"to":!_vm.urlOpenNewWindow ? _vm.handleBaseUrl : null},on:{"click":function($event){return _vm.onCardClick(_vm.handleBaseUrl)}}},[_c('div',{class:[
      {'header-sm': _vm.isMobile},
      {'header-md': _vm.isTablet},
      {'header-lg': _vm.isDesktop} ]},[(_vm._isRecommendedForMe && _vm.displayRecommendedBadge)?_c('div',{staticClass:"position-absolute justify-content-start align-items-start d-flex\n      w-100 p-3 h-100 z-index-1"},[_c('recommendation-badge-component',{attrs:{"label":_vm.$t('actions.for-you'),"size":"lg"}})],1):_vm._e(),_c('div',{staticClass:"d-flex pl-3 pr-2 pb-3 pt-2 position-absolute w-100 h-100 z-index-1"},[(_vm.exhibitor && _vm.exhibitor.logoFileResource)?_c('avatar-solo-widget',{staticClass:"align-self-end",attrs:{"src":_vm.FileResourceHelper.getFullPath(_vm.exhibitor && _vm.exhibitor.logoFileResource
          ? _vm.exhibitor.logoFileResource : null, 'w40'),"tooltip-text":_vm.exhibitor && _vm.exhibitor.name ? _vm.exhibitor.name : '',"with-borders":true,"size":"24"}}):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"d-flex align-items-center column-gap-2 ml-auto align-self-start"},[_c('button-icon-component',{staticClass:"w-fit",attrs:{"id":("bookmark-" + _vm.uid),"variant":_vm.bookmarked ? 'alt-2' : 'tertiary',"shape":"round","size":"md"},on:{"on-click":_vm.toggleBookmark},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":_vm.bookmarked
                  ? 'fa-solid fa-bookmark'
                  : 'fa-regular fa-bookmark',"icon-classes":!_vm.bookmarked ? 'text-neutral-n-8-dark-ink' : '',"container-classes":"icon-24"}})]},proxy:true}])})],1)])],1),(_vm.noImage)?_c('div',{staticClass:"empty p-3"}):_vm._e(),(_vm.displayFileResource)?_c('img',{staticClass:"image",class:_vm.isExpired ? 'archived' : '',attrs:{"alt":("image for " + _vm.name),"src":_vm.FileResourceHelper.getFullPath(_vm.displayFileResource, 'w1000')},on:{"error":function (e) {_vm.noImage = true}}}):_c('img',{class:_vm.isExpired ? 'archived' : '',attrs:{"src":_vm.FileResourceHelper.getFullPath(_vm.community.defaultDealBannerFileResource, 'w1000'),"alt":""}})]),_c('div',{staticClass:"body pt-3 pl-3 pr-3"},[(_vm.startTime && _vm.endTime)?_c('p',{staticClass:"medium text-left text-neutral-n-6-label mb-2 label-2 deal-date"},[_vm._v(" "+_vm._s(_vm.dealDate)+" ")]):_vm._e(),_c('h6',{staticClass:"name text-left text-neutral-n-8-dark-ink m-0"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]),((_vm.startTime || _vm.endTime))?_c('div',{staticClass:"d-flex mt-auto w-100 px-3 pb-12 gap-2 z-index-1",class:[
      {'align-items-end justify-content-end': !_vm.expireSoonTime},
      {'justify-content-between align-items-center': _vm.expireSoonTime}
    ]},[(_vm.expireSoonTime)?_c('p',{staticClass:"m-0 small text-red-r-3-primary-red"},[_vm._v(" "+_vm._s(_vm.expireSoonTime)+" ")]):_vm._e(),_c('div',{staticClass:"align-self-end"},[(_vm.isExpired && _vm.displayExpirationBadge && !_vm.expireSoonTime)?_c('pill-widget',{attrs:{"label":_vm.offerValidDate(),"with-borders":true,"size":"xs","variant":"dark-gray"}}):(!_vm.isExpired && _vm.displayExpirationBadge && _vm.expireSoonTime)?_c('pill-widget',{attrs:{"label":_vm.offerValidDate(),"with-borders":true,"size":"xs","variant":"red white-text"}}):_vm._e()],1)]):_vm._e(),(_vm.authUser)?_c('b-tooltip',{attrs:{"target":("bookmark-" + _vm.uid),"title":_vm.bookmarked
      ? _vm.$t('actions.tooltip.bookmark.remove')
      : _vm.$t('actions.tooltip.bookmark.add'),"boundary":"document","custom-class":"tooltip-style","placement":"top","triggers":"hover"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }